<template>
  <div class="CommunityTelphoneTel-container">
    <form-panel
      ref="form"
      :form="form"
      :submitUrl="submitUrl"
      :submitBefore="submitBefore"
      submitContentType="application/x-www-form-urlencoded;charset=UTF-8"
    >
      <col2-detail>
        <col2-block>
          <el-form-item label="新增电话" :rules="[{ required: true, message: '请填写电话信息', trigger: 'blur'}]">
            <div v-for="(item, index) in form.data" class="input-group" :key="index">
              <el-form-item style="display: inline-block;margin: 8px;" :prop="'data.'+index+'.community'" :rules="[{ required: true, message: '请选择项目', trigger: 'change'}]">
                <v-select2 placeholder="请选择项目" v-model="item.community" v-bind="projectParams"></v-select2>
              </el-form-item>
              <el-form-item style="display: inline-block; margin: 8px;" :prop="'data.'+index+'.telName'" :rules="[{ required: true, message: '请填写电话名称', trigger: 'blur'}]">
                <v-input placeholder="电话名称" v-model="item.telName" />
              </el-form-item>
              <el-form-item style="display: inline-block;margin: 8px;" :prop="'data.'+index+'.telNum'" :rules="[{ required: true, message: '请填写电话号码', trigger: 'blur'}]">
                <v-input placeholder="电话号码" v-model="item.telNum" :maxlength="20"/>
              </el-form-item>
              <el-form-item style="display: inline-block;margin: 8px;" :prop="'data.'+index+'.telType'" :rules="[{ required: true, message: '请选择电话类型', trigger: 'blur'}]">
                <v-select v-model="item.telType" :options="numTypeOps"></v-select>
              </el-form-item>
              <el-form-item style="display: inline-block;margin: 8px;" :prop="'data.'+index+'.telSortNum'"
                :rules="[{ required: true, message: '请输入排序号', trigger: 'blur'}]">
                <v-input-number v-model="item.telSortNum" placeholder="请输入排序号" controls controlsPosition="right" :min="0" />
              </el-form-item>
              <v-button v-if="index" type="danger" text="删除" @click="remove(index)" />
            </div>
            <div class="btn-group">
              <v-button text="添加+" @click="add" />
            </div>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { createURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { numTypeFormOps } from './map'
import { normalCommunityParams } from 'common/select2Params'

export default {
  name: 'CommunityTelphoneTel',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      submitUrl: createURL,
      projectParams: normalCommunityParams,
      numTypeOps: numTypeFormOps,
      form: {
        data: [{
          telNum: undefined
        }]
      }
    }
  },
  created () {
    //   this.numTypeOps.splice(0, 1)
  },
  methods: {
    add () {
      this.form.data.push({})
    },
    remove (index) {
      this.form.data.splice(index, 1)
    },
    submitBefore (data) {
      let community = data.data.map(item => item.community)
      let telName = data.data.map(item => item.telName)
      let telNum = data.data.map(item => item.telNum)
      let telType = data.data.map(item => item.telType)
      let telSortNum = data.data.map(item => item.telSortNum)
      data.community = community.join(',')
      data.telName = telName.join(',')
      data.telNum = telNum.join(',')
      data.telType = telType.join(',')
      data.telSortNum = telSortNum.join(',')
      delete data.data
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
  .link {
    color: #1b8cf2;
    cursor: pointer;
  }
  .orgs-modal-item {
    text-align: left;
  }
</style>
<style lang="scss">
.device-manager-common-form-container {
  .el-input {
    margin-right: 15px;
  }
  .input-group {
    display: flex;
    margin-bottom: 25px;
  }
  .input-content {
    .el-input {
      width: 300px !important;
    }
  }
  .btn-group {
    margin-top: 15px;
  }
}
</style>
