<template>
  <div class="">
    <import-template
    :uploadUrl="uploadURL"
    uploadLabel="电话数据"
    :downloadUrl="downloadUrl"
    @onSuccess="onSuccess"
    downloadText="下载模板">
    <div slot="remark">
       项目名称：必须是项目信息表中已有的项目名称;
       <div>
          <p>电话类型：必填，选择项目服务电话或公共服务电话;</p>
          <p>电话名称：必填，20个字符以内，填写示例：物业服务中心;</p>
          <p>电话号码：必填，20个字符以内，填写示例：0571-88475555.</p>
          <p>最大支持10MB的excel文件，超过请拆分导入</p>
       </div>
      </div>
    </import-template>
  </div>
</template>

<script>
import { uploadURL } from './api.js'
import { ImportTemplate } from '@/components/bussiness'
export default {
  name: 'CommunityTelphoneImport',
  components: {
    ImportTemplate
  },
  data () {
    return {
      downloadUrl: 'static/excel/常用电话列表.xls',
      uploadURL: uploadURL
    }
  },
  methods: {
    onSuccess () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
