var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "import-template",
        {
          attrs: {
            uploadUrl: _vm.uploadURL,
            uploadLabel: "电话数据",
            downloadUrl: _vm.downloadUrl,
            downloadText: "下载模板",
          },
          on: { onSuccess: _vm.onSuccess },
        },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _vm._v("\n     项目名称：必须是项目信息表中已有的项目名称;\n     "),
            _c("div", [
              _c("p", [
                _vm._v("电话类型：必填，选择项目服务电话或公共服务电话;"),
              ]),
              _c("p", [
                _vm._v("电话名称：必填，20个字符以内，填写示例：物业服务中心;"),
              ]),
              _c("p", [
                _vm._v(
                  "电话号码：必填，20个字符以内，填写示例：0571-88475555."
                ),
              ]),
              _c("p", [_vm._v("最大支持10MB的excel文件，超过请拆分导入")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }