// 获取列表
const getListURL = `${API_CONFIG.baseURL}communityTelphoneAction!telphoneList.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}communityTelphoneAction!exportToExcel.action`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}communityInfo/deletePhones`
// 删除
const deletedURL = `${API_CONFIG.baseURL}communityTelphoneAction!delTelphone.action`

// 新增
const createURL = `${API_CONFIG.baseURL}communityTelphoneAction!saveTelByForm.action`
// 上传
const uploadURL = `${API_CONFIG.butlerBaseURL}communityTelphone/importCommunityTelphone`
// 导入excel
const importExcel = `${API_CONFIG.uploadURL}?loginName=admin&module=excel`
// 详情
const getDetailUrl = `${API_CONFIG.baseURL}communityTelphoneAction!telEdit.action`
// 编辑
const updateUrl = `${API_CONFIG.baseURL}communityTelphoneAction!telUpdate.action`

export {
  getListURL,
  exportListURL,
  batchURL,
  deletedURL,
  createURL,
  uploadURL,
  importExcel,
  getDetailUrl,
  updateUrl
}
