var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CommunityTelphoneTel-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: {
            form: _vm.form,
            submitUrl: _vm.submitUrl,
            submitBefore: _vm.submitBefore,
            submitContentType:
              "application/x-www-form-urlencoded;charset=UTF-8",
          },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "新增电话",
                        rules: [
                          {
                            required: true,
                            message: "请填写电话信息",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _vm._l(_vm.form.data, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "input-group" },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "8px",
                                },
                                attrs: {
                                  prop: "data." + index + ".community",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择项目",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: { placeholder: "请选择项目" },
                                      model: {
                                        value: item.community,
                                        callback: function ($$v) {
                                          _vm.$set(item, "community", $$v)
                                        },
                                        expression: "item.community",
                                      },
                                    },
                                    "v-select2",
                                    _vm.projectParams,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "8px",
                                },
                                attrs: {
                                  prop: "data." + index + ".telName",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请填写电话名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input", {
                                  attrs: { placeholder: "电话名称" },
                                  model: {
                                    value: item.telName,
                                    callback: function ($$v) {
                                      _vm.$set(item, "telName", $$v)
                                    },
                                    expression: "item.telName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "8px",
                                },
                                attrs: {
                                  prop: "data." + index + ".telNum",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请填写电话号码",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input", {
                                  attrs: {
                                    placeholder: "电话号码",
                                    maxlength: 20,
                                  },
                                  model: {
                                    value: item.telNum,
                                    callback: function ($$v) {
                                      _vm.$set(item, "telNum", $$v)
                                    },
                                    expression: "item.telNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "8px",
                                },
                                attrs: {
                                  prop: "data." + index + ".telType",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择电话类型",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: { options: _vm.numTypeOps },
                                  model: {
                                    value: item.telType,
                                    callback: function ($$v) {
                                      _vm.$set(item, "telType", $$v)
                                    },
                                    expression: "item.telType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  margin: "8px",
                                },
                                attrs: {
                                  prop: "data." + index + ".telSortNum",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入排序号",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input-number", {
                                  attrs: {
                                    placeholder: "请输入排序号",
                                    controls: "",
                                    controlsPosition: "right",
                                    min: 0,
                                  },
                                  model: {
                                    value: item.telSortNum,
                                    callback: function ($$v) {
                                      _vm.$set(item, "telSortNum", $$v)
                                    },
                                    expression: "item.telSortNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            index
                              ? _c("v-button", {
                                  attrs: { type: "danger", text: "删除" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c("v-button", {
                            attrs: { text: "添加+" },
                            on: { click: _vm.add },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }