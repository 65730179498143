
// 电话类型
const numTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '项目服务电话',
    value: 1
  },
  {
    text: '公共服务电话',
    value: 2
  }
]
const numTypeFormOps = [
  {
    text: '项目服务电话',
    value: '1'
  },
  {
    text: '公共服务电话',
    value: '2'
  }
]

// 电话类型
const numTypes = {
  1: '项目服务电话',
  2: '公共服务电话'
}

export {
  numTypeOps,
  numTypes,
  numTypeFormOps
}
