<template>
  <div class="CommunityTelphoneForm-container">
     <tabs-panel :tabs="tabs" :tabRouter="false" :activeLabel.sync="activeLabel"></tabs-panel>
  </div>
</template>
<script>
import { TabsPanel } from 'components/bussiness'
import CommunityTelphoneImport from './CommunityTelphoneImport'
import CommunityTelphoneTel from './CommunityTelphoneTel'

export default {
  name: 'CommunityTelphoneForm',
  components: {
    TabsPanel
  },
  data () {
    return {
      activeLabel: '导入电话',
      tabs: []
    }
  },
  created () {
    this.tabs = [
      {
        label: '导入电话',
        component: CommunityTelphoneImport
      }, {
        label: '新增电话',
        component: CommunityTelphoneTel
      }
    ]
  },
  mounted () {
    this.$setBreadcrumb('新增-导入电话')
  },
  methods: {}
}
</script>
